/* Primitives */
:root {
  --color-white: #ffffff;
  --color-dark-blue: #1b1b50;
  --color-mid-blue: #071aa8;
  --color-blue: #4b64ea;
  --color-dark-red: #690f23;
  --color-red: #d91646;
  --color-green: #4ce17f;
  --color-grey: #919199;
  --color-beige: #e4e1dc;
}

/* Tokens */
:root,
.theme-base {
  --color-surface: transparent;
  --color-surface-solid: var(--color-white);
  --color-surface-cta: var(--color-green);
  --color-text: var(--color-dark-blue);
  --color-text-secondary: var(--color-dark-red);
  --color-text-tint: var(--color-grey);
  --color-text-cta: var(--color-dark-blue);
  --color-text-anchor: var(--color-dark-red);
  --color-accent: var(--color-beige);
  --color-accent-secondary: var(--color-blue);
  --color-accent-secondary-contrast: var(--color-white);
  --color-stroke: var(--color-dark-blue);
  --color-logo-icon: var(--color-dark-red);
  --color-card-border: var(--color-red);
}

.theme-brand-1 {
  --color-surface: var(--color-dark-blue);
  --color-surface-solid: var(--color-dark-blue);
  --color-surface-cta: var(--color-green);
  --color-text: var(--color-white);
  --color-text-secondary: var(--color-white);
  --color-text-tint: var(--color-grey);
  --color-text-cta: var(--color-dark-blue);
  --color-text-anchor: var(--color-mid-white);
  --color-accent: var(--color-blue);
  --color-accent-secondary: var(--color-white);
  --color-accent-secondary-contrast: var(--color-blue);
  --color-stroke: var(--color-white);
  --color-logo-icon: var(--color-white);
  --color-card-border: var(--color-white);
}

.theme-brand-2 {
  --color-surface: var(--color-dark-red);
  --color-surface-solid: var(--color-dark-red);
  --color-surface-cta: var(--color-green);
  --color-text: var(--color-white);
  --color-text-secondary: var(--color-white);
  --color-text-tint: var(--color-grey);
  --color-text-cta: var(--color-dark-blue);
  --color-text-anchor: var(--color-mid-white);
  --color-accent: var(--color-red);
  --color-accent-secondary: var(--color-white);
  --color-accent-secondary-contrast: var(--color-blue);
  --color-stroke: var(--color-white);
  --color-logo-icon: var(--color-white);
  --color-card-border: var(--color-white);
}

.theme-brand-3 {
  --color-surface: var(--color-blue);
  --color-surface-solid: var(--color-blue);
  --color-surface-cta: var(--color-green);
  --color-text: var(--color-white);
  --color-text-secondary: var(--color-white);
  --color-text-tint: var(--color-grey);
  --color-text-cta: var(--color-dark-blue);
  --color-text-anchor: var(--color-mid-white);
  --color-accent: var(--color-mid-blue);
  --color-accent-secondary: var(--color-white);
  --color-accent-secondary-contrast: var(--color-blue);
  --color-stroke: var(--color-white);
  --color-logo-icon: var(--color-white);
  --color-card-border: var(--color-white);
}
