@import './colors.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body,
  .theme-base,
  .theme-brand-1,
  .theme-brand-2,
  .theme-brand-3 {
    @apply text-text;
  }

  :focus-visible {
    @apply outline-none;
  }

  :focus-visible:not(.outline-on-child-focus-visible > *),
  .outline-on-child-focus-visible:has(:focus-visible) {
    @apply outline outline-2 outline-offset-2 outline-surface-cta;
  }

  ._headline-block + ._rich-text-block > div > ._container {
    @apply pt-4 lg:pt-6;
  }

  /* HubSpot form */
  .hs-form form {
    @apply grid gap-8; /* Form spacing*/
  }
  .hs-form input {
    @apply w-full mr-0;
  }
  .hs-form .input {
    @apply !mr-0;
  }
  .hs-form .form-columns-2 {
    @apply flex flex-col gap-8 xs:flex-row xs:gap-6;
  }
  .hs-form .form-columns-3 {
    @apply flex flex-col gap-8 xs:flex-row xs:gap-6;
  }
  .hs-form .submitted-message {
    @apply text-lg sm:text-xl;
  }
  .hs-form .hs-form-field > label {
    @apply font-bold mb-1 inline-block; /* Labels */
  }
  .hs-form .hs-form-field input:not([type='radio']):not([type='file']):not([type='checkbox']),
  .hs-form .hs-form-field textarea,
  .hs-form .hs-form-field select {
    @apply border border-stroke rounded-md !p-3 bg-surface-solid inline-block; /* Inputs, textareas & select */
  }
  .hs-form .hs-fieldtype-select .input {
    @apply relative;
    @apply before:absolute before:top-[1.1rem] before:right-3 before:size-4 before:[background-image:url('/assets/chevron-down.svg')] before:pointer-events-none;
  }
  .hs-form .hs-form-field select {
    @apply !appearance-none !cursor-pointer block p-3 pr-10 rounded-md border border-text w-full bg-surface-solid;
  }
  .hs-form ul.hs-error-msgs {
    @apply text-sm mt-1 grid gap-1; /* Error messages */
  }
  .hs-form .legal-consent-container {
    @apply max-w-3xl text-sm; /* Legal consent */
  }
  .hs-form .hs-fieldtype-file input {
    @apply cursor-pointer !inline !w-auto; /* File input */
  }
  .hs-form .hs-fieldtype-file .input {
    @apply mt-1 relative;
  }
  .hs-form .hs-fieldtype-file .input::after {
    @apply content-[''] absolute top-2.5 left-2.5 size-5 bg-[url('/assets/upload.svg')] bg-no-repeat bg-cover pointer-events-none;
  }
  .hs-form .hs-fieldtype-file ::file-selector-button {
    @apply bg-surface-cta text-surface-cta font-bold size-10 overflow-hidden border-none rounded-full mr-2 cursor-pointer bg-no-repeat bg-right; /* File input button */
  }
  .hs-form .hs-submit {
    @apply relative place-self-end;
    @apply before:absolute before:top-2 before:right-6 before:size-6 before:[background-image:url('/assets/button-arrow.svg')] before:-translate-x-[20%] before:transition-transform before:duration-300 before:ease-in-out before:pointer-events-none before:bg-no-repeat;
    @apply hover:before:translate-x-0;
    @apply after:absolute after:top-2 after:right-10 after:w-4 after:h-6 after:bg-surface-cta after:transition-[width,right] after:duration-300 after:ease-in-out after:pointer-events-none;
    @apply hover:after:w-0 hover:after:right-12;
  }
  .hs-form .hs-submit [type='submit'] {
    @apply bg-surface-cta text-text-cta font-bold pl-6 pr-14 py-2 cursor-pointer; /* Submit button */
  }

  /* Radio buttons */
  .hs-form input[type='radio'] {
    /* Radio circle */
    @apply appearance-none size-4 border border-stroke rounded-full bg-surface-solid;
    @apply checked:bg-surface-cta checked:[box-shadow:inset_0_0_0_2px_var(--color-surface-solid)];
  }
  .hs-form ul:has(> .hs-form-radio) {
    @apply inline-grid gap-2 mt-2 items-start; /* Radio list grid */
  }
  .hs-form .hs-form-radio-display {
    @apply flex items-center gap-2; /* Radio field */
  }
  .hs-form li.hs-form-radio {
    @apply max-w-fit; /* Radio item max width */
  }
  .hs-form li.hs-form-radio label {
    @apply cursor-pointer; /* Pointer */
  }

  /* Checkboxes */
  .hs-form .hs-fieldtype-checkbox ul {
    @apply inline-grid gap-2 mt-2 items-start;
  }
  .hs-form .hs-form-checkbox label {
    @apply cursor-pointer inline-flex gap-2 items-center relative;
  }
  .hs-form .hs-form-checkbox label::after {
    @apply content-[''] absolute top-1/2 left-0 size-4 -translate-y-1/2;
  }
  .hs-form .hs-form-checkbox label:has(> input:checked)::after {
    @apply bg-[url('/assets/checkmark.svg')] bg-no-repeat bg-cover;
  }
  .hs-form .hs-form-checkbox input {
    @apply appearance-none size-4 !p-0 bg-surface-solid rounded-sm border border-stroke cursor-pointer checked:bg-surface-cta checked:border-surface-cta;
  }

  /* Remove number input arrows */
  .hs-form input[type='number']::-webkit-inner-spin-button,
  .hs-form input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .hs-form input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield;
  }

  /* BRAINHUNT RICH TEXT */
  .bh-rte {
    @apply grid gap-4 text-sm sm:text-base;
  }
  .bh-rte h2 {
    @apply text-xl sm:text-2xl lg:text-3xl font-bold sm:mb-1 mt-2 sm:mt-4;
  }
  .bh-rte > h2:first-child {
    @apply mt-0;
  }
  .bh-rte h3 {
    @apply text-lg sm:text-xl lg:text-2xl font-bold sm:mb-1 mt-1 sm:mt-2;
  }
  .bh-rte img {
    @apply !w-full !h-auto block my-2;
  }
  .bh-rte video,
  .bh-rte iframe {
    @apply !w-full !h-auto aspect-video block my-2;
  }
  .bh-rte a {
    @apply underline inline-block;
  }
  .bh-rte ol,
  .bh-rte ul {
    @apply grid gap-2;
  }
  .bh-rte ol li {
    @apply ml-6 list-decimal;
  }
  .bh-rte ul li {
    @apply ml-6 list-disc;
  }
}

@media (min-width: 2500px) {
  :root {
    font-size: 18px;
  }

  .hs-form .hs-submit::before {
    @apply -translate-x-[15%] top-[0.55rem];
  }
}
